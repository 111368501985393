<template>

  <div>
    <unit-list-add-new
      :is-add-new-unit-sidebar-active.sync="isAddNewUnitSidebarActive"
      :unit-data="unitData"
      @reset-data="resetunitData"
      @refetch-data="getUnitData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getUnitData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="getUnitData()"
              />
              <b-button
                variant="primary"
                @click="isAddNewUnitSidebarActive = true"
              >
                <span class="text-nowrap">Add New Unit</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refunitListTable"
        class="position-relative"
        :items="unitList"
        responsive
        :fields="tableColumns"
        primary-key="unit_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getUnitData"
      >

        <!-- Column: User -->
        <template #cell(title)="data">
          <b-link
            :to="{ name: 'unit-view', params: { id: data.item.unit_id } }"
            class="font-weight-bold mb-50"
          >
            {{ data.item.title }}
          </b-link>
          <p class="small mb-50">
            {{ data.item.message }}
          </p>
        </template>

        <!-- Column: Updated At -->
        <template #cell(author)="data">
          <div class="text-nowrap">
            {{ data.item.author }}
            <template v-if="data.item.editor && data.item.editor !== data.item.author">
              <br><span class="small">Last edit by :</span> {{ data.item.editor }}
            </template>
          </div>
        </template>

        <!-- Column: Updated At -->
        <template #cell(updated_at)="data">
          <div class="text-nowrap">
            {{ data.item.updated_at | formatDate }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editData(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDel(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div
        class="mx-2 mb-2"
      >
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UnitListAddNew from './UnitListAddNew.vue'

// Notification

export default {
  components: {
    UnitListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTable,
    BLink,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewUnitSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'unit_name', label: 'Unit Kerja', sortable: true },
      { key: 'author', sortable: false },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('unit_id')
    const isSortDirDesc = ref(false)
    const roleFilter = ref(null)

    return {
      toast,
      // Sidebar
      isAddNewUnitSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      roleFilter,
    }
  },
  data() {
    return {
      unitList: [],
      dataMeta: '',
      unitData: {},
      update: false,
    }
  },
  created() {
    this.getUnitData()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getUnitData()
    },
    getUnitData() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/units'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.unitList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Unit list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.unitData = data
      this.isAddNewUnitSidebarActive = true
    },
    resetunitData() {
      this.unitData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/unit/delete', { unit_id: data.unit_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Unit has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getUnitData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error deleting Unit',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
